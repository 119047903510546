import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* <Title text="Projects" /> */
    }
    {
      /* - [Super Short Story Telling: Harry Potter](https://www.lekoarts.de/en/projects/docma-award-super-short-story-telling-about-harry-potter) */
    }
    {
      /* - [Instagram Project "Proprius"](https://www.lekoarts.de/en/projects/private-instagram-project-proprius) */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      